import "./Feed.css";
import globalStyles from "../components/global.module.css";
import { useState } from "react";
import { useFilterTimeline } from "./hooks/useFilterTimeline";
import HeaderFeed from "./HeaderFeed";
import { ContentResponse } from "./types/ContentResponse";
import cardsRenderer from "./cardsRenderer";
import { InView } from "react-intersection-observer";
import ContentCard from "./ContentCard";
import { CircularProgress } from "@mui/material";
import { FilterStoriesType } from "./types/FilterStoriesType";

interface FilterStoriesProps {
  filterType: FilterStoriesType;
}

function FilterStories({ filterType }: FilterStoriesProps) {
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  const handleInViewChange = (inView: boolean, id: string) => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isError,
    isLoading,
    isFetchingNextPage,
  } = useFilterTimeline({ type: filterType, numberOfStories: 5 });

  return (
    <>
      {isLoading && !isFetched ? (
        <div className={globalStyles.centralMessage}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <HeaderFeed />
          <div className="feed" key={`feed`}>
            {data?.pages?.map((page, pageIndex) =>
              page?.Stories?.map((contentItemInput, contentIndex) => {
                if (contentItemInput === null) return null;

                const contentItem = contentItemInput as ContentResponse;
                const cards = cardsRenderer(
                  contentItem.Cards,
                  contentItem.Id,
                  contentItem.DateId,
                  contentItem.Source
                );

                return (
                  <div
                    className="content_container"
                    key={`content-container-${contentItem.Id}`}
                  >
                    {contentIndex % 5 === 2 &&
                      !triggeringFetchIds.includes(contentItem.Id) && (
                        <InView
                          as="div"
                          onChange={(inView) =>
                            handleInViewChange(inView, contentItem.Id)
                          }
                        />
                      )}

                    <ContentCard
                      storyId={contentItem.Id}
                      storyDateId={contentItem.DateId}
                      favorite={contentItem.Favorite}
                      bookmark={contentItem.Bookmark}
                      likes={contentItem.Likes}
                      pageNumber={pageIndex}
                      key={`content-card-${contentItem.Id}`}
                    >
                      {cards}
                    </ContentCard>
                  </div>
                );
              })
            )}

            <InView
              className="inView"
              style={{ height: "10px", width: "100%" }}
              onChange={(inView) => {
                if (inView && hasNextPage && !isFetchingNextPage) {
                  fetchNextPage();
                }
              }}
              threshold={0.1}
            ></InView>
            {isError ? (
              <div className="feed__centeredMessage">Error loading content</div>
            ) : isFetchingNextPage ? (
              <div className="feed__centeredMessage">Loading more content</div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default FilterStories;
