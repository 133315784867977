import "./Contact.css";
import { ReactElement } from "react";
import MiniAvatar from "./MiniAvatar";
import { RecipientType } from "../../discussions/types/RecipientType";

export interface ContactProps {
  Name: string;
  UserName: string;
  ContactType: RecipientType;
  Avatar?: string;
  children?: ReactElement;
  onClick?: () => void;
}

function Contact(props: ContactProps) {
  return (
    <div className="contact__div" onClick={props.onClick}>
      <MiniAvatar
        height="40px"
        src={props.Avatar}
        name={props.Name}
        userName={props.UserName}
        characterType={props.ContactType}
      />
      <div className="contact__nameContainer">
        <div className="contact__name">{props.Name}</div>
        {props.UserName ? (
          <div className="contact__UserName">{`(@${props.UserName})`}</div>
        ) : null}
      </div>
      {props.children ? (
        <div className="contact__children">{props.children}</div>
      ) : null}
    </div>
  );
}

export default Contact;
