import styles from "./MyProfile.module.css";
import { useUserPreferencesData } from "../customHooks/useUserPreferencesData";
import ProfileAvatarSelector from "./ProfileAvatarSelector";
import EditablePersonalData from "./EditablePersonalData";
import { Link } from "react-router-dom";
import { ReactComponent as LeaveIcon } from "../components/icons/LeaveIcon.svg";
import { msalInstance } from "../apiClient";
import Icon from "@mui/material/Icon";
import SubscriptionStatus from "../subscriptions/SubscriptionStatus";
import {
  SubscriptionType,
  useMySubscription,
} from "../subscriptions/hooks/useMySubscription";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { stripeConfig } from "../customConfig";

function MyProfile() {
  const { data: myPreferences } = useUserPreferencesData();
  const { data: subscriptionData } = useMySubscription();

  function handleLogout(): void {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  return myPreferences ? (
    <div className={styles.profileContainer}>
      <div className={styles.profilePicture}>
        <ProfileAvatarSelector currentPicSrc={myPreferences.Avatar}>
          {subscriptionData &&
          subscriptionData.subscriptionType === SubscriptionType.Premium ? (
            <div className={styles.statusContainer}>
              <div className={`${styles.status} ${styles.premium}`}>
                Premium <WorkspacePremiumIcon />
              </div>
            </div>
          ) : null}
        </ProfileAvatarSelector>
      </div>
      <div>
        <div>
          <EditablePersonalData
            currentName={myPreferences.Name ?? ""}
            currentUserName={myPreferences.UserName}
          />
        </div>
        <div className={styles.listContainer}>
          <ListItem
            linkPath="/Preferences"
            text="My preferences"
            iconName="star"
          />
          <ListItem
            linkPath="./Favorites"
            text="Favorite news"
            iconName="favorite"
          />
          <ListItem
            linkPath="./Bookmarks"
            text="Bookmarks"
            iconName="bookmark"
          />
          {subscriptionData &&
          subscriptionData.subscriptionType === SubscriptionType.Premium ? (
            <ListItem
              linkPath={stripeConfig.manageAccountPath}
              text="Manage subscription"
              iconName="card_membership"
            />
          ) : null}
          {/* TODO: add path to manage subscription */}
        </div>
        <div className={styles.bottomContainer}>
          <SubscriptionStatus />
        </div>
        <div className={styles.bottomContainer}>
          <button className={styles.button} onClick={() => handleLogout()}>
            <LeaveIcon />
            <span>Log out</span>
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default MyProfile;

interface SelectableItemProps {
  linkPath: string;
  text: string;
  iconName: string;
}

const ListItem = ({ linkPath, text, iconName }: SelectableItemProps) => {
  return (
    <div className={styles.listItem}>
      <Link to={linkPath} className={styles.buttonLink}>
        <button className={styles.listItemButton}>
          <Icon className={styles.icon}>{iconName}</Icon>
          {text}
        </button>
      </Link>
    </div>
  );
};
