import { apiClient } from "../../apiClient";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";

interface GetSubscriptionResponse {
  userId: string;
  subscriptionType: SubscriptionType;
}

export enum SubscriptionType {
    Free = "Free",
    Premium = "Premium",
}

async function fetchMySubscription(): Promise<GetSubscriptionResponse> {
    const response: AxiosResponse<GetSubscriptionResponse> =
      await apiClient.get("subscriptions");
    return response.data;
}

export const useMySubscription = () => {

  return useQuery("mysubscription", fetchMySubscription, {
    staleTime: Infinity, // Data never goes stale
    cacheTime: 1000 * 5 * 60 * 24, // 5 minutes
    refetchOnWindowFocus: false,
  });
};
