import { useCallback, useState } from "react";

export function useCopyToClipboard() {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyTextToClipboard = useCallback(
    async (text: string): Promise<void> => {
      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      } catch (error) {
        console.error("Failed to copy:", error);
        setIsCopied(false);
      }
    },
    []
  );

  return { isCopied, copyTextToClipboard };
}
