import "./Feed.css";
import { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";
import ContentCard from "./ContentCard";
import AnimationScreen from "../components/AnimationScreen";
import { ContentResponse } from "./types/ContentResponse";
import HeaderFeed from "./HeaderFeed";
import cardsRenderer from "./cardsRenderer";
import { ReactComponent as LogoIcon } from "../components/icons/PaperPlanes.svg";
import { useFetchInfiniteFeed } from "./hooks/useFetchInfiniteFeed";

function Feed() {
  const [showEndingCard, setShowEndingCard] = useState<boolean>(false);
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  const handleInViewChange = (inView: boolean, id: string) => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isError,
    isLoading,
    isFetchingNextPage,
  } = useFetchInfiniteFeed();

  useEffect(() => {
    const handleKeyScroll = (event: KeyboardEvent) => {
      const feedElement = document.querySelector(".feed");

      if (event.key === "ArrowUp") {
        event.preventDefault();
        feedElement?.scrollBy({ top: -500, behavior: "smooth" });
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        feedElement?.scrollBy({ top: 500, behavior: "smooth" });
      }
    };

    document.addEventListener("keydown", handleKeyScroll);

    return () => {
      document.removeEventListener("keydown", handleKeyScroll);
    };
  }, []);

  return (
    <>
      {isLoading && !isFetched ? (
        <AnimationScreen loadingText="LOADING..." showTopMenu={true} />
      ) : (
        <>
          <HeaderFeed />
          <div className="feed" key={`feed`}>
            {data?.pages?.map((page, pageIndex) =>
              page?.Stories?.map((contentItemInput, contentIndex) => {
                if (contentItemInput === null) return null;

                const contentItem = contentItemInput as ContentResponse;
                const cards = cardsRenderer(
                  contentItem.Cards,
                  contentItem.Id,
                  contentItem.DateId,
                  contentItem.Source
                );

                return (
                  <div
                    className="content_container"
                    key={`content-container-${contentItem.Id}`}
                  >
                    {contentIndex % 5 === 2 &&
                      !triggeringFetchIds.includes(contentItem.Id) && (
                        <InView
                          as="div"
                          onChange={(inView) =>
                            handleInViewChange(inView, contentItem.Id)
                          }
                        />
                      )}
                    <ContentCard
                      storyId={contentItem.Id}
                      storyDateId={contentItem.DateId}
                      favorite={contentItem.Favorite}
                      bookmark={contentItem.Bookmark}
                      likes={contentItem.Likes}
                      pageNumber={pageIndex}
                      pullToRefetch={pageIndex === 0 && contentIndex === 0}
                      key={`content-card-${contentItem.Id}`}
                    >
                      {cards}
                    </ContentCard>
                  </div>
                );
              })
            )}

            <InView
              className="inView"
              style={{ height: "10px", width: "100%" }}
              onChange={(inView) => {
                if (inView && hasNextPage && !isFetchingNextPage) {
                  fetchNextPage();
                } else if (!hasNextPage) {
                  setShowEndingCard(true);
                }
              }}
              threshold={0.1}
            ></InView>
            {isError ? (
              <div className="feed__centeredMessage">Error loading content</div>
            ) : isFetchingNextPage ? (
              <div className="feed__centeredMessage">Loading more content</div>
            ) : hasNextPage ? (
              <div className="feed__centeredMessage">Loading more...</div>
            ) : showEndingCard ? (
              <div className="content_container">
                <div className="endingCard" key={`endingCardKey`}>
                  <div className="endingCard__content">
                    <p>There is no more content available for you now</p>
                    <p>Please check out later</p>
                    <div>
                      <LogoIcon />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
export default Feed;
