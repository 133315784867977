import globalStyles from "../../components/global.module.css";
import { MemberBasicInfoType } from "../../types/MemberBasicInfoType";
import Contact from "../../components/elements/Contact";
import React, { SetStateAction } from "react";
import { UserContactResponseType } from "../../types/UserContactResponseType";
import Checkbox from "@mui/material/Checkbox";
import { RecipientType } from "../../discussions/types/RecipientType";

interface SelectableContactsListProps {
  foundContacts: UserContactResponseType[];
  currentMembers: MemberBasicInfoType[];
  selectedContacts: string[];
  setSelectedContacts: React.Dispatch<SetStateAction<string[]>>;
}

function SelectableContactsList(props: SelectableContactsListProps) {
  const selectContact = (idSelected: string) => {
    const alreadySelectedContact = props.selectedContacts.find(
      (contact) => contact === idSelected
    );

    props.setSelectedContacts(
      alreadySelectedContact
        ? props.selectedContacts.filter(
            (contact) => contact !== alreadySelectedContact
          )
        : [...props.selectedContacts, idSelected]
    );
  };

  return (
    <div>
      {props.foundContacts.length === 0 ? (
        <div className={globalStyles.messageInfo}>
          <p>No contacts found</p>
        </div>
      ) : (
        props.foundContacts.map((contact) =>
          props.currentMembers.some((member) => member.id === contact.Id) ? (
            <Contact
              Name={contact.Name}
              UserName={contact.UserName}
              ContactType={RecipientType.User}
              Avatar={contact.Avatar}
              key={contact.Id}
            >
              <Checkbox
                sx={{
                  "&.Mui-disabled": {
                    color: "#d9d9d9", // color when disabled
                  },
                }}
                checked={true}
                disabled={true}
              />
            </Contact>
          ) : (
            <div
              onClick={() => selectContact(contact.Id)}
              key={`div${contact.Id}`}
            >
              <Contact
                Name={contact.Name}
                UserName={contact.UserName}
                ContactType={RecipientType.User}
                Avatar={contact.Avatar}
                key={contact.Id}
              >
                <Checkbox
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => selectContact(contact.Id)}
                  sx={{
                    color: "#0f5940", // default color
                    "&.Mui-checked": {
                      color: "#0f5940", // color when checked
                    },
                  }}
                  checked={props.selectedContacts.includes(contact.Id)}
                  disabled={false}
                />
              </Contact>
            </div>
          )
        )
      )}
    </div>
  );
}

export default SelectableContactsList;
