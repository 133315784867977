import GetPremiumButton from "./GetPremiumButton";
import { useMySubscription } from "./hooks/useMySubscription";
import { SubscriptionType } from "./hooks/useMySubscription";

const SubscriptionStatus = () => {
  const { data, isLoading, error } = useMySubscription();

  if (isLoading) {
    return <div>Loading subscription status...</div>;
  }

  if (error || !data) {
    return <div>Error loading subscription status</div>;
  }

  if (data.subscriptionType === SubscriptionType.Free) {
    return <GetPremiumButton />;
  } else return null;
};

export default SubscriptionStatus;
