import styles from "./GlobalSearchResultsList.module.css";
import Contact from "./Contact";
import { ReactComponent as ArrowDownIcon } from "../icons/ArrowDownIcon.svg";
import { useSearchWithinAllUsers } from "../../customHooks/useSearchWithinAllUsers";
import CircularProgress from "@mui/material/CircularProgress";
import { SetStateAction } from "react";
import SupportButton from "../../community/contacts/SupportButton";
import { UserContactResponseType } from "../../types/UserContactResponseType";
import { useQueryClient } from "react-query";
import { RecipientType } from "../../discussions/types/RecipientType";

interface GlobalSearchResultsListProps {
  searchPhrase: string;
  setSearchActive: React.Dispatch<SetStateAction<boolean>>;
}

function GlobalSearchResultsList(props: GlobalSearchResultsListProps) {
  const { data, isLoading, isError } = useSearchWithinAllUsers({
    searchPhrase: props.searchPhrase,
  });

  const queryClient = useQueryClient();

  const updateQuery = async (contactId: string, isSupported: boolean) => {
    await queryClient.cancelQueries(["fetchUsers", props.searchPhrase]);
    const contactsQueryData = queryClient.getQueryData<{
      MatchedUsers: UserContactResponseType[];
    }>(["fetchUsers", props.searchPhrase]);

    if (contactsQueryData) {
      const contactToUpdate = contactsQueryData.MatchedUsers.find(
        (contact) => contact.Id === contactId
      );

      if (contactToUpdate) {
        queryClient.setQueryData<
          { MatchedUsers: UserContactResponseType[] } | undefined
        >(["fetchUsers", props.searchPhrase], (prevData) => {
          if (prevData) {
            return {
              ...prevData,
              MatchedUsers: prevData.MatchedUsers.map((contact) =>
                contact.Id === contactId
                  ? { ...contact, IsSupported: !isSupported }
                  : contact
              ),
            };
          }
          return undefined;
        });
      }
    }
  };

  return props.searchPhrase.length > 0 ? (
    <div className={styles.container}>
      <div className={styles.list}>
        {data && data.MatchedUsers.length > 0 ? (
          data.MatchedUsers.map((user) => (
            <Contact
              Name={user.Name}
              UserName={user.UserName}
              ContactType={RecipientType.User}
              Avatar={user.Avatar}
              key={user.Id}
            >
              <SupportButton
                isSupported={user.IsSupported}
                contactId={user.Id}
                onSuccess={updateQuery}
              />
            </Contact>
          ))
        ) : data?.MatchedUsers.length === 0 ? (
          <div className={styles.infoMessage}>
            <p>No matches found</p>
          </div>
        ) : isLoading ? (
          <div className={styles.infoMessage}>
            <CircularProgress size={16} />
          </div>
        ) : isError ? (
          <div className={styles.infoMessage}>
            <p>Something went wrong, please try again later.</p>
          </div>
        ) : null}
      </div>
      <div
        className={styles.arrow}
        onClick={() => props.setSearchActive(false)}
      >
        <ArrowDownIcon />
      </div>
    </div>
  ) : null;
}
export default GlobalSearchResultsList;
