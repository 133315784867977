import { stripeConfig } from "../customConfig";
import { SubscriptionType, useMySubscription } from "./hooks/useMySubscription";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./PricingPage.module.css";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { ReactComponent as PaperPlanesLogo } from ".././components/icons/PaperPlanes.svg";

const PricingPage = () => {
  const { data, isLoading, error } = useMySubscription();

  if (isLoading || !data?.userId) {
    return <CircularProgress size={14} />;
  }

  if (error || !data) {
    return <div>Something went wrong. Try again</div>;
  }

  if (data.subscriptionType === SubscriptionType.Premium) {
    return (
      <div className={styles.alreadyPremium}>
        <PaperPlanesLogo className={styles.logo} />
        <span>
          You already have premium account
          <span>
            <WorkspacePremiumIcon />
          </span>
        </span>
      </div>
    );
  }
  return (
    <div className={styles.pricingList_container}>
      <stripe-pricing-table
        pricing-table-id={stripeConfig.pricingTableId}
        publishable-key={stripeConfig.publishableKey}
        client-reference-id={data.userId}
      />
    </div>
  );
};

export default PricingPage;
