import { useNavigate } from "react-router-dom";
import styles from "./GetPremiumButton.module.css";

function GetPremiumButton() {
  const navigate = useNavigate();
  const handleGetPremium = () => {
    navigate("/Pricing");
  };
  return (
    <button className={`${styles.buttonPremium}`} onClick={handleGetPremium}>
      Get Premium
    </button>
  );
}

export default GetPremiumButton;
