import { useState } from "react";
import { MemberBasicInfoType } from "../../types/MemberBasicInfoType";
import { ReactComponent as MoreIcon } from "../../components/icons/MoreIcon.svg";
import Contact from "../../components/elements/Contact";
import DialogWindow from "../../components/DialogWindow";
import { useRemoveContactFromGroup } from "../hooks/useRemoveContactFromGroup";
import { useChangeGroupOwnership } from "../hooks/useChangeGroupOwnership";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  errorToastOptions,
  successToastOptions,
} from "../../components/toastOptions";
import { CircularProgress } from "@mui/material";
import { RecipientType } from "../../discussions/types/RecipientType";

interface GroupMembersListProps {
  groupMembers: MemberBasicInfoType[];
  groupOwner: MemberBasicInfoType;
  isOwner: boolean;
  groupId: string;
}

function GroupMembersList(props: GroupMembersListProps) {
  const [memberToEdit, setMemberToEdit] = useState<{
    id: string;
    userName: string;
  }>();
  const [showEditMembershipDialog, setShowEditMembershipDialog] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleMoreButtonClick = (memberId: string, memberUserName: string) => {
    setMemberToEdit({ id: memberId, userName: memberUserName });
    setShowEditMembershipDialog(true);
  };

  const { mutate: removeFromGroup, isLoading: isRemoving } =
    useRemoveContactFromGroup();
  const { mutate: makeGroupOwner, isLoading: isChangingOwnership } =
    useChangeGroupOwnership();

  const handleRemoveUser = (groupId: string, idToRemove: string) => {
    removeFromGroup(
      { groupId, idToRemove },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["group", groupId]);
          setShowEditMembershipDialog(false);
          toast.success(
            `${memberToEdit?.userName} removed from the group.`,
            successToastOptions
          );
        },
        onError: (error) => {
          toast.error(
            `Something went wrong. We couldn't remove ${memberToEdit?.userName} from the group.`,
            errorToastOptions
          );
        },
      }
    );
  };

  const handleMakeOwner = (groupId: string, newOwnerId: string) => {
    makeGroupOwner(
      {
        groupId,
        newOwnerId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["group", groupId]);
          setShowEditMembershipDialog(false);
          toast.success(
            `${memberToEdit?.userName} has been successfully set as the new owner.`,
            successToastOptions
          );
        },
        onError: (error) => {
          toast.error(
            `Something went wrong. We couldn't set ${memberToEdit?.userName} as the new owner`,
            errorToastOptions
          );
        },
      }
    );
  };

  return (
    <>
      {props.groupMembers.map((member: MemberBasicInfoType) => (
        <Contact
          Name={member.name}
          UserName={member.userName}
          ContactType={RecipientType.User}
          Avatar={member.avatar}
          key={member.id}
        >
          {props.groupOwner.id === member.id ? (
            <div>Admin</div>
          ) : props.isOwner ? (
            <MoreIcon
              height="16px"
              onClick={() => handleMoreButtonClick(member.id, member.userName)}
            />
          ) : undefined}
        </Contact>
      ))}
      {memberToEdit && showEditMembershipDialog && (
        <DialogWindow
          question={memberToEdit.userName}
          options={[
            {
              optionName: isRemoving ? (
                <CircularProgress size={14} />
              ) : (
                "Remove from the group"
              ),
              handleClick: () => {
                handleRemoveUser(props.groupId, memberToEdit.id);
              },
              fontColor: "var(--red-font)",
              isDisabled: isRemoving,
            },
            {
              optionName: isChangingOwnership ? (
                <CircularProgress size={14} />
              ) : (
                "Make owner"
              ),
              handleClick: () =>
                handleMakeOwner(props.groupId, memberToEdit.id),
              isDisabled: isChangingOwnership,
            },
          ]}
          extraButtonCallback={() => setShowEditMembershipDialog(false)}
          extraButtonName="Cancel"
        />
      )}
    </>
  );
}

export default GroupMembersList;
