export const stripeConfig = {
  pricingTableId:
    process.env.REACT_APP_PRICING_TABLE_ID === undefined
      ? ""
      : process.env.REACT_APP_PRICING_TABLE_ID,
  publishableKey:
    process.env.REACT_APP_PUBLISHABLE_KEY === undefined
      ? ""
      : process.env.REACT_APP_PUBLISHABLE_KEY,
  manageAccountPath:
    process.env.REACT_APP_MANAGE_ACCOUNT_PATH === undefined
      ? ""
      : process.env.REACT_APP_MANAGE_ACCOUNT_PATH,
};
